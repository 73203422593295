<template>
    <v-expansion-panels class="mb-6">
        <v-expansion-panel>
            <v-expansion-panel-title v-slot="{ open }" @click="openPanel = !openPanel">
                <v-row no-gutters :class="open ? 'px-4' : 'px-2'">
                    <!-- Expand to Filter / Advance Filter text -->
                    <v-col :cols="open ? 12 : 3" :class="openPanel ? 'text-h6 font-weight-bold' : 'font-weight-bold'">
                        {{ openPanel ? 'Advance Filter' : 'Expand to Filter' }}
                    </v-col>

                    <!-- Details row, visible when the filter is collapsed -->
                    <v-col v-if="!openPanel" cols="9" class="d-none d-md-flex align-center text-secondary">
                        <v-fade-transition leave-absolute>
                            <v-row class="w-100">
                                <!-- Date/Time and Location columns -->
                                <v-col cols="3">
                                    <v-row>
                                        <v-col class="text-start" cols="12">
                                            Date/Time: {{ dateTime || 'Not set' }}
                                        </v-col>
                                        <v-col class="text-start" cols="12">
                                            Location: {{ locationName || 'Not set' }}
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <!-- Agent column -->
<!--                                <v-col class="text-start" cols="3">-->
<!--                                    Agent: {{ agentName || 'Not set' }}-->
<!--                                </v-col>-->

                                <!-- Client column -->
                                <v-col class="text-start" cols="3">
                                    Client: {{ clientName || 'Not set' }}
                                </v-col>
                            </v-row>
                        </v-fade-transition>
                    </v-col>
                </v-row>
            </v-expansion-panel-title>
            <v-expansion-panel-text>
                <v-form lazy-validation @submit.prevent="$emit('search', model)">
                    <!-- First Row: Date, Client, Agent -->
                    <v-row class="px-5 pb-0">
                        <!-- Date/Time Picker -->
                        <v-col cols="12" md="6" lg="4">
                            <v-menu v-model="menu" transition="scale-transition" :close-on-content-click="false" offset-y>
                                <template v-slot:activator="{ props }">
                                    <div v-bind="props">
                                        <v-tooltip location="top" :disabled="!dateTime">
                                            <template v-slot:activator="{ props }">
                                                <v-text-field v-model="dateTime" variant="outlined" placeholder="Date / Time"
                                                              prepend-inner-icon="mdi-calendar" readonly hide-details="auto" clearable v-bind="props" @click:clear="clearDate" density="compact">
                                                </v-text-field>
                                            </template>
                                            <span>{{ dateTime }}</span>
                                        </v-tooltip>
                                    </div>
                                </template>
                                <DateTimePicker
                                    v-model="modelDateTime"
                                    :menuValue="menu"
                                    :initialDate="date"
                                    :modelValue="[model.ts, model.te]"
                                />
                            </v-menu>
                        </v-col>
                        <!-- Client Select -->
                        <v-col cols="12" md="6" lg="4">
                            <v-select v-model="model.client_id" density="compact" :items="clientsList" item-title="text" item-value="value" variant="outlined" placeholder="---Select Client---"
                            :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true }"
                            hide-details="auto" clearable @click:clear="$emit('remove', 'client_id')">
                            </v-select>
                        </v-col>

                        <!-- Agent Select -->
<!--                        <v-col cols="12" md="6" lg="4">-->
<!--                            <v-select v-model="model.agent_id" density="compact" :items="agentsList" item-title="text" item-value="value" variant="outlined" placeholder="-&#45;&#45;Select Agent-&#45;&#45;"-->
<!--                            :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true }"-->
<!--                            hide-details="auto" clearable @click:clear="$emit('remove', 'agent_id')" @update:modelValue="model.agent_name = agentName">-->
<!--                            </v-select>-->
<!--                        </v-col>-->
                    </v-row>

                    <!-- Second Row: Location, Search, Reset -->
                    <v-row class="px-5 pb-0">
                        <!-- Location Select -->
                        <v-col cols="12" md="6" lg="4">
                            <v-select v-model="model.location_state" density="compact" :items="stateList" item-title="text" item-value="value" variant="outlined" placeholder="---Select Location---"
                            :menu-props="{ closeOnClick: true, bottom: true, offsetY: true, closeOnContentClick: true }"
                            hide-details="auto" clearable @click:clear="$emit('remove', 'location_state')" @update:modelValue="model.location_name = locationName">
                            </v-select>
                        </v-col>

                        <!-- Search Button -->
                        <v-col cols="12" md="6">
                            <v-btn type="submit" color="primary" class="mr-4 mt-2" :loading="loading"
                            :disabled="loading || !Object.keys(model).some(key => model[key])">
                            Search
                            </v-btn>

                            <!-- Reset Button -->
                            <v-btn type="button" variant="outlined" color="primary" class="mr-4 mt-2" @click="resetForm"
                            :disabled="loading">
                            Reset
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-expansion-panel-text>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

import DateTimePicker from '../../../components/common/DateTimePicker.vue';

export default {
    components: {
        DateTimePicker,
    },
    props: ['loading'],
    emits: [ 'search', 'reset', 'remove'],
    data: () => ({
        model: {
            ts: null,
            te: null,
            type: null,
            agent_id: null,
            client_id: null,
            location_state: null
        },
        typeList: [{ text: 'Arch', value: 'arch'}, {text: 'Chasis', value: 'chasis'}, {text: 'Tire', value: 'tire'}],
        stateList: [
            { text: 'ACT - Australian Capital Territory - AUS', value:'ACT'}, { text: 'NSW - New South Wales - AUS', value: 'NSW'},
            { text: 'NT - Northern Territory - AUS', value:'NT'}, { text: 'QLD - Queensland - AUS', value:'QLD'},
            { text: 'SA - South Australia - AUS', value:'SA'}, { text: 'TAS - Tasmania - AUS', value:'TAS'},
            { text: 'VIC - Victoria - VIC', value:'ACT'}, { text: 'WA - Western Australia - AUS', value:'WA'}
        ],
        menu: false,
        date: {
            start: null,
            end: null,
        },
        reseted: false,
        openPanel : false,
    }),
    computed: {
        ...mapGetters(['agentsList', 'clientsList']),

        modelDateTime: {
            get() {
                return [this.model.ts, this.model.te];
            },
            set(value) {
                this.model.ts = value[0];
                this.model.te = value[1];
            }
        },
        dateTime: {
            get() {
                if (!this.model.ts && !this.model.te) return null;
                return (`${this.model.ts ? this.$helpers.getMomentDatas('DD-MM-YYYY hh:mm A', this.model.ts) + ' - ': ''}${this.$helpers.getMomentDatas('DD-MM-YYYY hh:mm A', this.model.te)}`).trim();
            },
            set(value) {
                if(!value) {
                    this.model.ts = this.model.te = null;
                }
            }
        },
        agentName() {
            const agent = this.agentsList.find(item => item.value === this.model.agent_id);
            return this.model.agent_id && agent ? agent.text : null;
        },
        clientName() {
            const client = this.clientsList.find(item => item.value === this.model.client_id);
            return this.model.client_id && client ? client.text : null;
        },
        locationName() {
            const location = this.stateList.find(item => item.value === this.model.location_state);
            return this.model.location_state && location ? location.text : null;
        },
    },
    created() {
        this.getLocationLists();
        this.getAgentLists();
        this.getClientLists();
        this.setDefaultDateRange();
    },
    methods: {
        ...mapActions(['getLocationLists', 'getAgentLists', 'getClientLists']),

        setDefaultDateRange() {
            const today = new Date();
            const tomorrow = new Date(today);
            tomorrow.setDate(today.getDate() + 2);

            this.date = [today, tomorrow];
        },
        clearDate() {
            this.model.ts = this.model.te = null;
            this.date = [];
            this.$emit('remove', 'ts');
            this.$emit('remove', 'te');

            this.setDefaultDateRange();
        },
        resetForm() {
            this.reseted = true;
            this.$emit('reset');
            this.model = {
                ts: null,
                te: null,
                status: null,
                type: null,
                agent_id: null,
                client_id: null,
                location_id: null
            };
            this.date = [];
            this.setDefaultDateRange();
            setTimeout(() => {
                this.reseted = false;
            });
        }
    }
}
</script>

<style scoped>
.range-calendar {
    width: 350px;
}
</style>
