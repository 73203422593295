import scans from '@/store/modules/scans/api';
import globalHelper from '../../../helpers/global.helper';

export default {
    getScanLists({ commit }, params) {
        return new Promise((res, rej) => {
            scans.scansList(params).then(response => {
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    },
    getSelectedScan({ commit }, id) {
        commit("setScanLoading", true);
        return new Promise((res, rej) => {
            scans.scanModel(id).then(response => {
                commit("setScanLoading", false);
                res(response);
            }).catch((error) => {
                commit("setScanLoading", false);
                rej(error)
            });
        });
    },
    getScanModel({ commit }, { endPoint, params }) {
        return new Promise((res, rej) => {
            scans.scanModel(endPoint, { params }).then(response => {
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    },
    setScanRefresh({ commit }, id) {
        const reqData = {
            "command": "scan:json",
            "params": {
              "--id": id
            }
        }
        return new Promise((res, rej) => {
            scans.createModel('artisan', reqData).then(response => {
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    },
    updateSavedImage({ commit }, data) {
        commit("setAnnotationLoading", true);
        return new Promise((res, rej) => {
            scans.createModel(`scans/${data.id}/annotates`, data.file).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: `${data.edit} Image Saved Successfully`,
                    color: "success",
                });
                commit("setAnnotationLoading", false);
                res(response);
            }).catch((error) => {
                commit("setAnnotationLoading", false);
                rej(error);
            });
        });
    },
    searchMetaArchData({ commit }, data) {
        commit("setMetaArchLoading", true);
        const endpoint = globalHelper.setURLparams(data, 'archData/search?', 'object');
        return new Promise((res, rej) => {
            scans.getScanMetaData(endpoint).then(response => {
                res(response);
                commit("setArchMetaDetails", response);
            }).catch((error) => {
                commit("setMetaArchLoading", false);
                rej(error)
            });
        });
    },
    getLicenseAutoComplete({ commit }, data) {
        return new Promise((res, rej) => {
            scans.createModel(`license-plates/search`, data).then(response => {
                res(response);
            }).catch((error) => {
                rej(error);
            });
        });
    },
    fetchLicense({ commit }, {number, state}) {
        const endPoint = state ? `?state=${state}` : '';
        return new Promise((res, rej) => {
            scans.getscanModel(`license-plates/fetch/${number}${endPoint}`).then(response => {
                res(response.data);
            }).catch((error) => {
                rej(error);
            });
        });
    },
    updateSelectedScan({ commit }, data) {
        if(!data.noLoading) commit("setScanLoading", true);
        return new Promise((res, rej) => {
            scans.updateScanModel(data).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Scan Updated Successfully",
                    color: "success",
                });
                commit("setScanLoading", false);
                res(response);
            }).catch((error) => {
                commit("setScanLoading", false);
                rej(error)
            });
        });
    },
    updateEditDamageCount({ commit }, data) {
        if(!data.noLoading) commit("setScanLoading", true);
        return new Promise((res, rej) => {
            scans.editDamageCount(data).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Damage Count Updated Successfully",
                    color: "success",
                });
                commit("setScanLoading", false);
                res(response);
            }).catch((error) => {
                commit("setScanLoading", false);
                rej(error)
            });
        });
    },
    deleteScan({ commit }, scanId) {
        return new Promise((res, rej) => {
            scans.deleteScanModel(scanId).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Scan Deleted Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    },
    deleteAnnotateImg({ commit }, imgId) {
        return new Promise((res, rej) => {
            scans.deleteScanModel(`${imgId}/annotates`).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Saved Image Deleted Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    },
    createScan({ commit }, data) {
        commit("setScanLoading", true);
        return new Promise((res, rej) => {
            scans.createModel('scans',data).then(response => {
                commit("setScanLoading", false);
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Scan Created Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                commit("setScanLoading", false);
                rej(error)
            });
        });
    },
    exportScanReport({ commit }, { endPoint, params }) {
        return new Promise((res, rej) => {
            scans.exportReport(endPoint, { params }).then(response => {
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    },
}
