import quote from '@/store/modules/quote/api';

export default {
    getQuoteEvaluation({ commit }, scanId) {
        commit("setQuoteDetailLoading", true);
        return new Promise((res, rej) => {
            quote.getQuoteEvaluate(scanId).then(response => {
                commit("setQuoteDetails", response);
                res(response);
            }).catch((error) => {
                commit("setQuoteDetails", null);
                rej(error)
            });
        });
    },
    createQuote({ commit }, data) {
        commit("setQuoteLoading", true);
        return new Promise((res, rej) => {
            quote.createQuoteModel(data).then(response => {
                commit("setQuoteLoading", false);
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Quote Generated Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                commit("setQuoteLoading", false);
                rej(error)
            });
        });
    },
    getSelectedQuote({ commit }, id) {
        commit("setQuoteLoading", true);
        return new Promise((res, rej) => {
            quote.quoteModel(id).then(response => {
                commit("setQuoteLoading", false);
                res(response);
            }).catch((error) => {
                commit("setQuoteLoading", false);
                rej(error)
            });
        });
    },
    removeVehiclePhotoQuote({ commit }, id) {
        commit("setQuoteLoading", true);
        return new Promise((res, rej) => {
            quote.removeVehiclePhoto(id).then(response => {
                commit("setQuoteLoading", false);
                res(response);
            }).catch((error) => {
                commit("setQuoteLoading", false);
                rej(error)
            });
        });
    },
    searchQuote({ commit }, id) {
        commit("setQuoteLoading", true);
        const reqData = {scan_id: id};
        return new Promise((res, rej) => {
            quote.searchQuoteModel(reqData).then(response => {
                commit("setQuoteLoading", false);
                res(response);
            }).catch((error) => {
                commit("setQuoteLoading", false);
                rej(error)
            });
        });
    },
    scanSearch({ commit }, data) {
        return new Promise((res, rej) => {
            quote.getQuoteModel('scans/search', data).then(response => {
                res(response);
            }).catch((error) => {
                rej(error);
            });
        });
    },
    deleteQuote({ commit }, id) {
        return new Promise((res, rej) => {
            quote.deleteQuoteModel(id).then(response => {
                commit("snackbar/SHOW_MESSAGE", {
                    text: "Quote Deleted Successfully",
                    color: "success",
                });
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    },

    exportQuoteReport({ commit }, { endPoint, params }) {
        return new Promise((res, rej) => {
            quote.exportReport(endPoint, { params }).then(response => {
                res(response);
            }).catch((error) => {
                rej(error)
            });
        });
    },
}