<template>
    <div>
        <h6 class="text-uppercase font-weight-regular" v-if="lists.length">
            {{name == 'p2p' ? 'Push to Paint' : name}}
        </h6>
        <v-row class="ma-0 align-center justify-space-between" v-if="lists.length">
            <v-col cols="6" class="font-weight-bold text-black">Item:</v-col>
            <v-col cols="6" class="font-weight-bold d-flex align-center justify-end text-black">Amount:</v-col>
        </v-row>
        <template v-for="item in processedLists">
            <div :key="item.key" v-if="name !== 'pdr' || item.category">
                <v-row class="ma-0 align-center justify-space-between">
                    <v-col cols="6" class="py-1">
                        <span class="text-uppercase">{{ item.label }}</span>
                        <span class="ml-1" v-if="name === 'pdr'">({{item.category}})</span>
                    </v-col>
                    <v-col cols="6" class="d-flex align-center justify-end py-1">
                       {{ currencyFormat(item.price ?? 0) }}
                    </v-col>
                </v-row>
            </div>
        </template>
        <v-row class="ma-0 align-center justify-space-between" v-if="lists.length">
            <v-col cols="6"></v-col>
            <v-col cols="6" class="font-weight-bold d-flex align-center justify-end text-black">
                <span class="mr-3 ">Subtotal</span>
              {{ currencyFormat(total ?? 0) }}
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    props: {
        lists: Array,
        name: String,
        total: Number,
        currency: String,
    },
    computed: {
        processedLists() {
            return this.lists.map((item) => ({
                key: this.name === 'products' ? item.value : item.panel_id,
                label: this.name === 'products' ? item.text : item.label,
                price: this.name === 'products' ? item.price : item.lineTotal,
                category: item.category || null,
            }));
        },
    },
    methods: {
        currencyFormat(amount) {
            return this.currency+' '+Number(amount).toFixed(2)
        },
    }
}
</script>

<style>

</style>
